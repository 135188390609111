import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Name } from '../components'

const Container = styled.div`
  padding-top: 12px;
  max-width: 1080px;
  width: 100%;
  margin: auto;
`
const Top = styled.div`
  padding-bottom: 12px;
  display: flex;
  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

const Header = props => {
  return (
    <Container>
      <Top>
        <Name location={props.location} />
        {/* {props.location !== '/' && <Navigation />} */}
      </Top>
    </Container>
  )
}
export default Header
