import React, { useState } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import posed from 'react-pose'
import { Waypoint } from 'react-waypoint'

import { Title, Copy } from '../components'

const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
})

const Container = styled(Box)`
  display: grid;
  margin: 2rem 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
`

const Text = styled.div`
  grid-column: 1/9;
  grid-row: 1/-1;
  margin: auto 0;
  z-index: 2;
`

const Image = styled.div`
  grid-column: 8/-1;
  grid-row: 1/-1;
  z-index: 1;
  .gatsby-image-wrapper {
    max-height: 600px;
  }
`

const PostLink = styled.div`
  margin-top: auto;
  a {
    color: black;
    text-decoration: none;
    transition: all 0.5s;
    &:hover {
      color: ${props => `rgb(${props.red}, ${props.green},${props.blue})`};
    }
  }
`

const Post = ({ data }) => {
  const [isVisible, setVisible] = useState(false)
  const { title, path, thumb } = data.node.frontmatter
  const excerpt = data.node.excerpt
  const colour = {
    r: Math.floor(Math.random() * 255),
    g: Math.floor(Math.random() * 255),
    b: Math.floor(Math.random() * 255),
  }
  console.log(colour)
  return (
    <Waypoint
      onEnter={() => setVisible(true)}
      onLeave={() => setVisible(false)}
    >
      <Container pose={isVisible ? 'visible' : 'hidden'}>
        <Text>
          <PostLink red={colour.r} green={colour.g} blue={colour.b}>
            <Link to={path}>
              <Title>{title}</Title>
            </Link>
          </PostLink>
          <Copy html={excerpt} />
        </Text>
        <Image>{thumb && <Img fluid={thumb.childImageSharp.fluid} />}</Image>
      </Container>
    </Waypoint>
  )
}

Post.propTypes = {}

export default Post
