import { Link } from 'gatsby'
import React from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'

const Container = styled.div`
  margin-left: 2rem;
  ${media.lessThan('medium')`
  margin-left: 0;
  `}
`

const List = styled.ul`
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
`

const Item = styled.li`
  a {
    font-size: 1.25rem;
    font-family: 'vg', sans-serif;
    margin-right: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: var(--black);
    padding-bottom: 3px;
  }
`

const NavLink = styled(Link)`
  border-bottom: 2px solid transparent;
`

const active = {
  borderBottom: '2px solid black',
}

const Navigation = () => (
  <Container>
    <List>
      <Item>
        <NavLink to="/work" activeStyle={active}>
          portfolio
        </NavLink>
      </Item>
      <Item>
        <NavLink to="/about" activeStyle={active}>
          about
        </NavLink>
      </Item>
      <Item>
        <NavLink to="/resume" activeStyle={active}>
          resume
        </NavLink>
      </Item>
      {/* <Item onClick={() => props.navigate('work')}>work</Item> */}
    </List>
  </Container>
)

export default Navigation
