import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  a {
    font-family: 'hngl', sans-serif;
    font-size: 3.5rem;
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
`

const Name = () => (
  <Container>
    <Link to="/">rondie li</Link>
  </Container>
)

Name.propTypes = {
  siteTitle: PropTypes.string,
}

Name.defaultProps = {
  siteTitle: ``,
}

export default Name
