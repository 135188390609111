import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import posed from 'react-pose'
import { Waypoint } from 'react-waypoint'

import { Copy } from '../components'

const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
})

const Container = styled(Box)`
  display: flex;
  &:hover a {
    background: rgb(245, 245, 245);
  }
`

const Text = styled.div`
  z-index: 2;
  order: 1;
  display: flex;
  h3 {
    font-family: 'bluu', monospace;
    margin: auto 0;
    font-size: 2rem;
  }
`

const PostLink = styled.div`
  margin: auto 0;
  a {
    background: rgb(253, 253, 253);
    color: black;
    transition: all 0.5s;
    padding: 0.25rem 1rem;
    text-decoration: none;
    &:hover {
      background: rgb(240, 240, 240);
    }
  }
`

const Excerpt = ({ data }) => {
  const [isVisible, setVisible] = useState(false)
  const { title, path, thumb } = data.node.frontmatter
  const excerpt = data.node.excerpt
  return (
    <Waypoint
      onEnter={() => setVisible(true)}
      onLeave={() => setVisible(false)}
    >
      <Container pose={isVisible ? 'visible' : 'hidden'}>
        <Text>
          <PostLink>
            <h3>{title}</h3>
          <Copy html={excerpt} />
          </PostLink>
        </Text>
      </Container>
    </Waypoint>
  )
}

Excerpt.propTypes = {}

export default Excerpt
