import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const Date = ({ children }) => (
  <Container>
    <span>{children}</span>
  </Container>
)

Date.propTypes = {
  children: PropTypes.string,
}

Date.defaultProps = {
  children: ``,
}

export default Date
