import { createGlobalStyle } from 'styled-components'
import * as velvetyne from './webfonts'
import 'typeface-poppins'

const GlobalStyle = createGlobalStyle`
  @font-face {
   font-family: 'vg';
   src: url(${velvetyne.vgEOT});
   src: url(${velvetyne.vgEOT}) format('embedded-opentype'),
        url(${velvetyne.vgWOFF}) format('woff'),
        url(${velvetyne.vgWOFF2}) format('woff2'),
        url(${velvetyne.vgTTF})  format('truetype');
 }


 @font-face {
   font-family: 'hngl';
   src: url(${velvetyne.hnglEOT});
   src: url(${velvetyne.hnglEOT}) format('embedded-opentype'),
        url(${velvetyne.hnglWOFF}) format('woff'),
        url(${velvetyne.hnglTTF})  format('truetype');
        url(${velvetyne.hnglSVG})  format('svg');
 }

 @font-face {
   font-family: 'mrpx';
   src: url(${velvetyne.mrpxEOT});
   src: url(${velvetyne.mrpxEOT}) format('embedded-opentype'),
        url(${velvetyne.mrpxWOFF}) format('woff'),
        url(${velvetyne.mrpxSVG}) format('svg'), 
        url(${velvetyne.mrpxTTF})  format('truetype');
 }

 @font-face {
   font-family: 'bluu';
   src: url(${velvetyne.bluuWOFF}) format('woff'),
        url(${velvetyne.bluuWOFF2}) format('woff2'),
        url(${velvetyne.bluuTTF})  format('truetype');
        url(${velvetyne.bluuSVG})  format('svg');
 }

 :root {
   --black: #050507; 
   --white: #FCFCFC; 
   --grey: #eeeeee; 
   --darkgrey: #e0e0e0; 
   --yellow: #fff176;
   --pink: #fce4ec;
   --blue: #6FB3D6;
   --red: #ED405B;
   --lightblue: #e1f5fe;
 }

 * {
    font-family: 'IBM Plex Sans', sans-serif;
    line-height: 150%;
 }
 
 a {
   color: var(--black);
 }

 p {
    font-size: 1.125rem;
 }

 html {
  overflow-y: scroll;
 }
 body, html {
    // background: var(--grey);
    color: var(--black);
    margin: 0;
 }
`

export default GlobalStyle
