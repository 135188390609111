import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import posed from 'react-pose'
import { Waypoint } from 'react-waypoint'

import { Title, Frame } from '../components'

const Box = posed.div({
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
})

const Container = styled(Box)`
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  transition: all 0.3s;
`

const Intro = styled.p`
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.875rem;
  margin-top: 0;
`

const PostLink = styled.div`
  margin-top: 36px;
  a {
    color: black;
    transition: all 0.5s;
    padding: 0.25rem 0 0;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    &:hover {
      padding-bottom: 0.25rem;
      border-bottom: 2px solid var(--black);
    }
  }
`
const Case = ({ data }) => {
  const [isVisible, setVisible] = useState(false)
  const { title, path, thumb, intro, domain } = data.node.frontmatter
  return (
    <Waypoint
      onEnter={() => setVisible(true)}
      onLeave={() => setVisible(false)}
    >
      <PostLink>
        <Link to={path}>
          <Container pose={isVisible ? 'visible' : 'hidden'}>
            <Title>{title}</Title>
            <Intro>{intro}</Intro>
          </Container>
        </Link>
      </PostLink>
    </Waypoint>
  )
}

export default Case
