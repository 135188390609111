import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const Copy = ({ html }) => (
  <Container dangerouslySetInnerHTML={{ __html: html }} />
)

Copy.propTypes = {
  html: PropTypes.string,
}

Copy.defaultProps = {
  html: ``,
}

export default Copy
