import React from 'react'
import styled from 'styled-components'

const Container = styled.footer`
  width: 100%;
  max-width: 1080px;
  margin: auto;
`

const Text = styled.p`
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.5rem;
`
// const Subtext = styled(Text)`
//   font-size: 0.5rem;
//   opacity: 0.3;
//   transition: all 0.5s;
//   &:hover {
//     opacity: 1;
//     cursor: help;
//   }
// `
const FooterLink = styled.a`
  color: black;
`

const Footer = () => (
  <Container>
    <Text>
      © {new Date().getFullYear()} Rondie Li
      {/* <br />
      Built with <FooterLink href="https://www.gatsbyjs.org">
        Gatsby
      </FooterLink>{' '}
      and a few lovely fonts from{' '}
      <FooterLink href="https://www.velvetyne.fr">VTF</FooterLink> */}
    </Text>
  </Container>
)

export default Footer
