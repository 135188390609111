import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Location } from '@reach/router'

import GlobalStyle from './GlobalStyle'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Header, Footer } from '../sections'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${media.lessThan('large')`
    padding: 0 2rem;
  `}
`

const Main = styled.main`
  flex: 1;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto 2rem;
`

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <GlobalStyle />
          <div className="application">
            <Helmet>
              <meta charSet="utf-8" />
              <title>{data.site.siteMetadata.title}</title>
              <link rel="canonical" href="http://rondie.ca" />
            </Helmet>
          </div>
          <Container>
            {/* <Location>
              {({ location }) => <Header location={location.pathname} />}
            </Location> */}
            <Main>{children}</Main>
            <Footer />
          </Container>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
