import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Bar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
  transition-delay: 0.3s;
`

const Image = styled.div``

const Dots = styled.div`
  display: flex;
  height: 100%;
  margin-left: 12px;
`
const Dot = styled.div`
  border-radius: 100%;
  height: 10px;
  width: 10px;
  margin: auto 3px;
  background: transparent;
  transition: all 0.5s;
  transition-delay: 0.3s;
  &:first-of-type:hover {
    background: tomato;
  }
  &:nth-of-type(2):hover {
    background: gold;
  }
  &:last-of-type:hover {
    background: forestgreen;
  }
`
const Domain = styled.div`
  border-radius: 50px;
  margin: auto;
  margin-left: 5%;
  margin-right: 2%;
  height: 22px;
  width: 100%;
  background: transparent;
  padding-left: 12px;
  font-size: 0.875rem;
  transition: all 0.5s;
  transition-delay: 1.5s;
  span {
    font-family: 'space mono', monospace;
    color: darkgrey;
    opacity: 0;
    transition: all 0.5s;
    transition-delay: 0.4s;
  }
`

const Container = styled.div`
  margin-top: 24px;
  border: 1px solid transparent;
  transition: all 0.5s;
  transition-delay: 0.3s;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
    ${Bar} {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    ${Dot} {
      background: #eee;
    }
    ${Domain} {
      background: #f1f1f1;
      span {
        opacity: 1;
      }
    }
  }
`
const Frame = ({ image, domain }) => {
  return (
    <Container>
      <Bar>
        <Dots>
          <Dot></Dot>
          <Dot></Dot>
          <Dot></Dot>
        </Dots>
        <Domain>
          <span>{domain}</span>
        </Domain>
      </Bar>
      <Image>
        <Img fluid={image} />
      </Image>
    </Container>
  )
}
export default Frame
