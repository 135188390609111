import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'

const Heading = styled.h3`
  font-size: 3.5rem;
  letter-spacing: 0.5;
  font-family: 'bluu', monospace;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
  ${media.lessThan('medium')`
  line-height: 130%;
  margin-bottom: 0.25rem;
  `}
`

const Title = ({ children }) => <Heading>{children}</Heading>

Title.propTypes = {
  children: PropTypes.string,
}

Title.defaultProps = {
  children: ``,
}

export default Title
